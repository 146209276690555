// import React, { Component } from 'react';
import React, { Component, createRef } from "react";
import { useParams } from 'react-router-dom';
import HyperModal from 'react-hyper-modal';
import axios from "axios";
import './home.css';
import { BACKEND_URI } from '../../config';
import emailjs from '@emailjs/browser';

class Home extends Component {
  constructor(props) {
    super(props);
    this.fNameRef = createRef()
    this.lnameRef = createRef()
    this.emailRef = createRef()
    this.companyRef = createRef()
    this.phoneRef = createRef()
    this.messageRef = createRef()
    this.fNameMobileRef = createRef()
    this.lnameMobileRef = createRef()
    this.emailMobileRef = createRef()
    this.companyMobileRef = createRef()
    this.phoneMobileRef = createRef()
    this.messageMobileRef = createRef()
    this.state = {
      isModalOpen: false,
      isLoading: true,
      title: "",
      blurb: ""
    };
  }

formClear() {
  this.emailRef.current.value="";
  this.fNameRef.current.value="";
  this.lnameRef.current.value="";
  this.companyRef.current.value="";
  this.phoneRef.current.value="";
  this.messageRef.current.value="";
  this.emailMobileRef.current.value="";
  this.fNameMobileRef.current.value="";
  this.lnameMobileRef.current.value="";
  this.companyMobileRef.current.value="";
  this.phoneMobileRef.current.value="";
  this.messageMobileRef.current.value="";
};

// openMobileNav () {
//   document.getElementById("myNav").style.height = "100%";
// }

closeMobileNav () {
  alert("ha!");
  document.getElementById("myNav").style.height = "0";
}

formSubmit () {
  const templateParams = {
    fName: this.fNameRef.current.value,
    guest: this.fNameRef.current.value + " " + this.lnameRef.current.value,
    company: this.companyRef.current.value,
    guestEmail: this.emailRef.current.value,
    guestPhone: this.phoneRef.current.value,
    message: this.messageRef.current.value
};
emailjs.send('service_e0ru5vn','template_xzx1t1l', templateParams, 'tk5NryehwfMcAPlRj')
  .then((response) => {
    console.log('GUEST EMAIL SUCCESS!', response.status, response.text);
});
emailjs.send('service_e0ru5vn','template_t1c2qo5', templateParams, 'tk5NryehwfMcAPlRj')
	.then((response) => {
	   console.log('BKORSLIN EMAIL SUCCESS!', response.status, response.text);
     alert("Thank you for sumbitting your inquiry w/ khce.  We will get back to you within 48 business hours.");
     document.getElementById("tabs").scrollIntoView({behavior: "smooth"});
     this.emailRef.current.value="";
     this.fNameRef.current.value="";
     this.lnameRef.current.value="";
     this.companyRef.current.value="";
     this.phoneRef.current.value="";
     this.messageRef.current.value="";
     this.emailMobileRef.current.value="";
     this.fNameMobileRef.current.value="";
     this.lnameMobileRef.current.value="";
     this.companyMobileRef.current.value="";
     this.phoneMobileRef.current.value="";
     this.messageMobileRef.current.value="";
	}, (err) => {
	   console.log('FAILED...', err);
	});
}
  // if (this.emailRef.current.value == "") {
  //   alert("Please enter a valid Email Address.")
  //    }   else if (this.fNameRef.current.value == "") {
  //   alert("Plesse enter your First Name.")
  //    }  else if (this.lnameRef.current.value == "") {
  //   alert("Please enter your Last Name.") 
  //    }  else if (this.companyRef.current.value == "") {
  //   alert("Please enter your Company Name.") 
  //   } else {
  //     axios({
  //       method: "POST", 
  //       url: `${BACKEND_URI}/submitBigScreen`,
  //       data: {
  //         name: this.fNameRef.current.value +" "+ this.lnameRef.current.value,   
  //         email: this.emailRef.current.value,  
  //         phone:  this.phoneRef.current.value,
  //         company:  this.companyRef.current.value,
  //         message: this.messageRef.current.value
  //       }}).then(function (res) {
  //       // }}).then(function (res) {
  //       alert("Thank you for your inquiry!  KorslinHCE will reach out to you shortly...");
  //       document.getElementById("mySidenav").style.width = "0";
  //       document.getElementById("arrow").style.display = "none";

  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       alert("There was an error sending your message.  Please try again later!");
  //       document.getElementById("arrow").style.display = "none";
  //       document.getElementById("mySidenav").style.width = "0";
  //     });
// }};

openCity (event) {
  // alert("Tab Name? " + event.target.value);
  const tabcontent = document.getElementsByClassName("tabcontent");
  const tablinks = document.getElementsByClassName("tablinks");
  for (var i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }
  // tablinks = document.getElementsByClassName("tablinks");
  for (var j = 0; j < tablinks.length; j++) {
    tablinks[j].className = tablinks[j].className.replace("active", "");
    // tabcontent[j].className = tabcontent[j].className.replace("active", "");
  }
  document.getElementById(event.target.value).style.display = "block";
  event.currentTarget.className += " active";
};

scrollToDiv () {
  document.getElementById("tabs").scrollIntoView({behavior: "smooth"});
};

scrollToContactDiv () {
  document.getElementById("row-contact-us-page").scrollIntoView({behavior: "smooth"});
};

mobileScrollDown () {
  document.getElementById("why-p").scrollIntoView({behavior: "smooth"});
};

signUp() {
    window.location.href="/register";
  };

 servicesPage() {
    document.getElementById("tabs").scrollIntoView({behavior: "smooth"});
  };

  expertise() {
    window.location.href="/expertise";
  }

  populateBlurb(event) {
    // alert("event: " + event.currentTarget.dataset.div_id);
    this.setState({ blurb: event.currentTarget.dataset.div_id });
    this.setState({ title: event.currentTarget.dataset.bind });
    document.getElementById("click-below-try").scrollIntoView({behavior: "smooth"})
  };

  blueTarget () {
    window.scrollTo({
      top: 1500,
      behavior: 'smooth'
  })
  }


  backToTop () {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
  })
  }


  openModal() {
    this.setState({ isModalOpen: true });
  };

  closeModal() {
    this.setState({ isModalOpen: false });
  };

  componentDidMount () {
    document.getElementById("top").scrollIntoView({behavior: "smooth"});
  };
  

  render() {
    const { isModalOpen } = this.state;
    return (
      <div id="top">
        <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
          <div className="carousel-inner">
              <div className="carousel-item active">
              {/* <div className="white-hamburger" id="white-hamburger" onClick={this.openMobileNav.bind(this)}><i className="fa-sharp fa-solid fa-bars"></i></div> */}
              <img className="rodgers" src="riverwalk-min.jpg" loading="eager" alt="First slide"/>
              <img className="rodgers-cell" src="riverwalk-mobile.jpg" alt="First slide"/>
                <div className="carousel-caption">
                  <div className="magazine-border"></div>
                  <div className="magazine-border-2"></div>
                  <div className="text-border-3"><text className="chicago-text">CONSULTING</text></div>
                  <img className="hcelogo-home" src="papa-logo.png" alt="HCE Logo"/>
                  {/* <h3 className="h3-title">Healthcare Business Advisory</h3><br/> */}
                  {/* <button type="button" onClick={this.servicesPage.bind(this)} className="btn btn-primary">LEARN MORE</button> */}
                  {/* <div className="main-p-text">KorslinHCE is a business advisory consulting firm that works with its clients to drive growth, inspire innovation, and achieve transformational change.
                   ensuring clients are positioned to take advantage of opportunities unfolding in a rapidly changing post-acute care marketplace.
                   <br/><br/><br/>
                  </div> */}
                  <div className="main-p-text"><span className="korslin-home-page">Consulting</span></div>
                  <button type="button" onClick={this.servicesPage.bind(this)} className="btn btn-primary">LEARN MORE</button>
                  <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                  <br/><br/>
                  <br/><br/>
                  {/* <i onClick={this.blueTarget.bind(this)} className="las la-angle-down"></i> */}
                </div>
              </div>
          </div>
          <div className="tab" id="tabs">
            <button className="tablinks active" value="Home" onMouseOver={this.openCity.bind(this)} onClick={this.scrollToDiv.bind(this)}>Home</button>
            <button className="tablinks" value="Expertise" onMouseOver={this.openCity.bind(this)} onClick={this.scrollToDiv.bind(this)}>Expertise</button>
            <button className="tablinks" value="Services" onMouseOver={this.openCity.bind(this)} onClick={this.scrollToDiv.bind(this)}>Services</button>
            <button className="tablinks" value="Process" onMouseOver={this.openCity.bind(this)} onClick={this.scrollToDiv.bind(this)}>Process</button>
            <button className="tablinks" value="Contact" onMouseOver={this.openCity.bind(this)} onClick={this.scrollToContactDiv.bind(this)}>Contact</button>
          </div>
          </div>
          
          {/* Tab content */}
          <div id="Home" className="tabcontent active">         
            <br/>
            <div className="row-button">
            <div className="col-md-3"></div>
              <div className="col-md-5"><h4 className="paradigm-shift">Healthcare Business Advisory</h4><div className="right-border"></div></div>
              <div className="col-md-4"><button type="button" onClick={this.servicesPage.bind(this)} className="btn-beneath-billboard">ABOUT US</button>
            </div>
            </div>
            <div className="line-5"></div>
              <div className="mobile-home-page-title">Healthcare<br/>Business Advisory</div>
              <button type="button" onClick={this.servicesPage.bind(this)} className="btn btn-primary-mobile">ABOUT US</button>
              <br/><br/><br/><i className="fa-sharp fa-light fa-angle-right"></i><div onClick={this.mobileScrollDown.bind(this)} className="mobile-under-caption">&nbsp;&nbsp;Learn More</div>
              <div className="row-2">
            <div id="why-p" className="why-p">We work with our clients to identify & implement operational performance improvement
              opportunities aimed at achieving sustainable success by focusing on customer
              experience, employee engagement, continual process reengineering, organization
              structure revisions and innovative technology development strategies.<br/><br/>
            {/* <h4 className="what-we-do">What we do...</h4>KHCE Consulting is a healthcare strategic planning consulting firm that works with its clients to drive growth, inspire innovation and achieve transformational cultural change in a rapidly changing environment. 
              We do this through creative and thoughtful collaboration with our clients, assisting them in evaluating their busines and, where necessary, modifying strategies to ensure they are positioned to take advantage of opportunities unfolding in the marketplace.
              We apply decades of healthcare industry experience to address the many challenges facing our clients in today’s world.  
            </div><br/> */}
            <div className="col-md-7">
            <h4 className="what-we-do">What we do...</h4>KHCE Consulting is a healthcare strategic planning consulting firm that works with its clients to drive growth, inspire innovation and achieve transformational cultural change in a rapidly changing environment. 
              We do this through creative and thoughtful collaboration with our clients, assisting them in evaluating their busines and, where necessary, modifying strategies to ensure they are positioned to take advantage of opportunities unfolding in the marketplace.
              We apply decades of healthcare industry experience to address the many challenges facing our clients in today’s world.  
            </div><br/>
            </div>
              <div className="col-md-5 text-right">
                <img src="target.jpg" alt="stethoscope" className="steth-img"/>
                {/* <div className="card-2-steth">
              </div> */}
              </div>
              <div className="card-2-steth"><br/>
              </div>
            </div>
            <div className="mobile-bottom">         
              <br/>
              <div className="line-3"></div>
              <br/>
              <div className="lower-text-bottom"><text>KorslinHCE applies decades of healthcare industry experience to address the many challenges facing our clients in today’s world.</text></div><br/>
              </div>
              <div className="bottom">
                  <br/>
                  {/* <div className="line">
                </div> */}
                <br/>
                {/* <button type="button" onClick={this.servicesPage.bind(this)} className="btn btn-primary-below">LEARN MORE</button> */}
                  <div className="line-tablet">
                </div>
                  <br/>
                    {/* <img src="steth.jpg" alt="stethoscope" className="steth-img"/> */}
                 <br/>
              </div>
                  {/* <div className="why-p-bottom">We apply decades of healthcare industry experience to address the many <br/>challenges facing our clients in today’s world.</div> */}
                  <br/><br/><br/><br/><br/><br/>
                  <div className="line">
                </div>
                  <div className="social-media-icons">
                      <a target="_blank" href="http://linkedin.com/in/william-korslin-7a211312a/"><img src="linked-in-icon.png" className="social-icon" alt="linked-in"/></a>
                      </div>
                  <br/>
          </div>

          <div id="Expertise" className="tabcontent">
          <br/>
            <div className="row-button">
            <div className="col-md-3"></div>
              <div className="col-md-5"><h4 className="paradigm-shift">Expertise</h4><div className="right-border"></div></div>
              <div className="col-md-4"><button type="button" onClick={this.servicesPage.bind(this)} className="btn-beneath-billboard">ABOUT US</button>
            </div>
            </div><br/><br/><br/>
            <div className="row-2">
            <div className="why-p">KHCE Consulting is a healthcare strategic planning consulting firm that works with its clients to drive
              growth, inspire innovation and achieve transformational cultural change in a rapidly changing
              environment.

              We do this through creative and thoughtful collaboration with our clients, assisting them in
              evaluating their busines and, where necessary, modifying strategies to ensure they are
              positioned to take advantage of opportunities unfolding in the marketplace.</div><br/><div id="click-below-try" className="click-below-try">[Click Below to Read More]</div>
            </div><br/><br/>
            <div className="mobile-bottom">         
              <br/>
              <div className="line-3"></div>
              <br/>
              <div className="lower-text-bottom"><text>KorslinHCE applies decades of healthcare industry experience to address the many challenges facing our clients in today’s world.</text></div><br/>
              </div>
        <div className="row-500">
        <h3 className="expertise-title">Expertise</h3><br/><br/><br/>
        {/* <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 padding-0"></div> */}
        <div className="col-xs-5 col-sm-5 col-md-5 col-lg-5 col-xl-5 padding-0">
          <ul className="expertise-list" id="expertise-list">
            <li className="expertise-list-item" onClick={this.populateBlurb.bind(this)} data-bind={"Financial Systems | Performance Measurement KPIs | Financial Planning & Reporting"} data-div_id={"We believe a fundamental cornerstone of financial strength is a well-developed financial system which is fully integrated with the Company's operating system as a threshold requirement.  More important is the information it provides and how the team is aligned around and aware of P&L responsibilities.  KPIs, dashboards, performance improvement programs, goal and performance measuring metrics should influence decision-making regarding organization structure, business model modifications, process re-engineering requirements and responsibilities and future plans."} >Financial Systems | Performance Measurement KPIs | Financial Planning & Reporting</li>
            <li className="expertise-list-item" onClick={this.populateBlurb.bind(this)} data-bind={"Financial Strength Assessment"} data-div_id={"Our Assessment process begins with a financial review to understand the Company's financial position (overall and by business unit).  A detailed review of financial reports (profit & loss, cash flow and balance sheet trends) is completed to provide a full understanding of the clients position to guide transformation or performance improvement processes."}>Financial Strength Assessment</li>
            <li className="expertise-list-item" onClick={this.populateBlurb.bind(this)} data-bind={"Supply Chain | Procurement Opportunities"} data-div_id={"Product costs and the way those products are brought to your customers represent major factors in profit & loss performance and customer experience.  Our experts apply over four decades of experience to help our clients optimize supply chain and procurement efficiencies."}>Supply Chain | Procurement Opportunities</li>
            <li className="expertise-list-item" onClick={this.populateBlurb.bind(this)} data-bind={"Culture Development | Transformation Guidance"} data-div_id={"We work with our client leadership to understand the Company's human resource & culture development initiatives and how they lean into Mission, Vision, Values, Strengths and Drivers."}>Culture Development | Transformation Guidance</li>
            <li className="expertise-list-item" onClick={this.populateBlurb.bind(this)} data-bind={"Operations Assessment | Performance Improvement"} data-div_id={"We collaborate with our clients to assess present state operations, operating and financial systems, organization structure, business models and growth strategies to identify opportunities to achieve greater operational success.  We then help design new strategies and implementation plans to achieve sustainable transformational change."}>Operations Assessment | Performance Improvement</li>
            <li className="expertise-list-item" onClick={this.populateBlurb.bind(this)} data-bind={"Ecosystem | Industry Trend Analysis"} data-div_id={"We provide a client-specific ecosystem analysis along with industry trends analytics to help position the Company to take advantage of opportunities unfolding in the marketplace.  COVID-19 has accelerated both innovation and innovation adoption, which will have a profound effect on the future of healthcare delivery, which will create many opportunities."} >Ecosystem | Industry Trend Analysis</li>
            <li className="expertise-list-item" onClick={this.populateBlurb.bind(this)} data-bind={"Employee Engagement & Development | Organization Transformation"} data-div_id={"Any company's most important asset is its people.  We work with our clients to understand organization structure, process improvements that may require organizational redesign, human resource allocation, talent acquisition and training programs as well as training programs as well as vision building the right team & the right sized team"}>Employee Engagement & Development | Organization Transformation</li>
            <li className="expertise-list-item" onClick={this.populateBlurb.bind(this)} data-bind={"Customer Experience Enhancement | Growth Strategy Development"} data-div_id={"A key pillar of success for all companies is how customers perceive their relationship.  Managing customer expectations is challenging in today's evolving marketplace, particularly, in the DMEPOS segment where customer satisfaction is measured by the beneficiary, the physician, the facility where the beneficiary resides and the payer.  Our team works with you to create effective go-to-market sales and marketing strategies and helps develop technologies required to capture meaningful performance data and communicate across your ecosystem."}>Customer Experience Enhancement | Growth Strategy Development</li>
        </ul>
      </div>
      <div className="col-xs-7 col-sm-7 col-md-7 col-lg-7 col-xl-7 padding-0">
        <div className="blurb-container">
         <h3 className="side-title">{this.state.title}</h3>
         <text className="blurb">{this.state.blurb}</text>
         </div>
      </div>
      {/* <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 padding-0"></div> */}

      {/* <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 padding-0"></div> */}
      </div>
      {/* <div className="stones-img-container">
            <img src="stones-min.jpg" className="zen-stone-img" alt="zen"/>
         </div> */}
         <br/>
         {/* <br/> */}
         {/* <br/><br/><br/><br/> */}
                  <div className="line">
                </div>
             <div className="social-media-icons">
                      <a target="_blank" href="http://linkedin.com/in/william-korslin-7a211312a/"><img src="linked-in-icon.png" className="social-icon" alt="linked-in"/></a>
                      </div>
                  <br/>
          </div>

          <div id="Services" className="tabcontent">
          <br/>
            <div className="row-button">
            <div className="col-md-3"></div>
              <div className="col-md-5"><h4 className="paradigm-shift">Services</h4><div className="right-border"></div></div>
              <div className="col-md-4"><button type="button" onClick={this.servicesPage.bind(this)} className="btn-beneath-billboard">ABOUT US</button>
            </div>
            </div>
            <div className="line-5"></div>
              <div className="mobile-home-page-title">Healthcare<br/>Business Advisory</div>
              <button type="button" onClick={this.servicesPage.bind(this)} className="btn btn-primary-mobile">LEARN MORE</button>
              <br/><br/><br/><i className="fa-sharp fa-light fa-angle-right"></i><div className="mobile-under-caption">&nbsp;&nbsp;Learn Even More</div>
              <div className="row-2">
            <div className="why-p">We do this through creative and thoughtful collaboration with our clients, assisting them in evaluating their business and, where necessary, modifying strategies to ensure our clients are positioned to take advantage of opportunities unfolding in the marketplace.  </div><br/><br/><br/><br/><br/><br/>
            </div>
            <div className="card-services"><br/>
            <ul className="services-list-right-stop">
              <li className="services-list-item">Strategic Business Assessment</li>
                <li className="services-list-item">Operational Value Stream Mapping & Performance Improvement</li>
                <li className="services-list-item">Business Model Evaluation & S.W.O.T. Analysis</li>
                <li className="services-list-item">Organization Structure & Team Assessment</li>
                <li className="services-list-item">Customer Experience Enhancement Programs</li>
                <li className="services-list-item">M&A Guidance</li>
                <li className="services-list-item">Ecosystem and Trend Analysis</li>
                <li className="services-list-item">Product Expansion, Sales & Marketing Strategy Development</li>
                <li className="services-list-item">Technology Assessment & IT Innovation Strategy</li>
                <li className="services-list-item">Competitition Assessment:  Competitive Advantages/Value Proposition</li>
                <li className="services-list-item">Financial Systems Analysis & Development Strategies</li>
              </ul>
              </div>
              {/* <div className="col-md-5 text-right">
                <div className="card-2-steth">
              </div>
              </div>
              <div className="card-2-steth"><br/>
              </div> */}
            {/* </div> */}
            <div className="mobile-bottom">         
              <br/>
              <div className="line-3"></div>
              <br/>
              <div className="lower-text-bottom"><text>KorslinHCE applies decades of healthcare industry experience to address the many challenges facing our clients in today’s world.</text></div><br/>
              </div>
              <div className="bottom">
                  <br/>
                  <div className="line">
                </div>
                <br/>
                                  <div className="social-media-icons">
                      <a target="_blank" href="http://linkedin.com/in/william-korslin-7a211312a/"><img src="linked-in-icon.png" className="social-icon" alt="linked-in"/></a>
                      </div>
                  <br/>
          </div>
          </div>
          <div id="Process" className="tabcontent">
          <br/>
            <div className="row-button">
            <div className="col-md-3"></div>
              <div className="col-md-5"><h4 className="paradigm-shift">The Assessment Process</h4><div className="right-border"></div></div>
              <div className="col-md-4"><button type="button" onClick={this.servicesPage.bind(this)} className="btn-beneath-billboard">ABOUT US</button>
            </div>
            </div>
            <div className="line-5"></div>
              <div className="mobile-home-page-title">Healthcare<br/>Business Advisory</div>
              <button type="button" onClick={this.servicesPage.bind(this)} className="btn btn-primary-mobile">LEARN MORE</button>
              <br/><br/><br/><i className="fa-sharp fa-light fa-angle-right"></i><div className="mobile-under-caption">&nbsp;&nbsp;Learn Even More</div>
              <div className="row-2">
            <div className="why-p">We do this through creative and thoughtful collaboration with our clients, assisting them in evaluating their business and, where necessary, modifying strategies to ensure our clients are positioned to take advantage of opportunities unfolding in the marketplace.  </div><br/>
            <div className="col-md-7 text-left">
            </div>
              <div className="col-md-5 text-right">
                <div className="card-2-steth">
              </div>
              </div>
              <div className="card-2-steth"><br/>
              </div>
            </div>
            <div className="mobile-bottom">         
              <br/>
              <div className="line-3"></div>
              <br/>
              <div className="lower-text-bottom"><text>KorslinHCE applies decades of healthcare industry experience to address the many challenges facing our clients in today’s world.</text></div><br/>
              </div>
              <div className="row-100">
                      <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <ul className="process-list">
                      <li className="process-list-item">Understand the Company's financial health/position (overall & broken down by business unit)</li>
                      <li className="process-list-item">Understand the Company's value proposition (specifically, how it's measured) and core competencies (i.e., its business model, processes, systems and people)</li>
                      <li className="process-list-item">Understand the Company's risks & ecosystem (including risk mitigation strategies such as diversification and projected trends of business units, manufacturers, distributors, competitors, customers, payers)</li>
                      <li className="process-list-item">Review Strategic, Operational, and Financial Plan; Mission; and Vision (individually)</li>
                      <li className="process-list-item">Understand the Company's technology development plan and corporate governance</li>
                      </ul>
              </div>
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <ul className="process-list-2" start="6">
                      <li className="process-list-item-2">Review to Understand the Company's personnel & culture development initiatives (i.e., Mission, Vision, Values, Strengths, and Drivers)</li>
                      <li className="process-list-item-2">Understand the Strategic, Operations, and Financial Plan and how they have been reviewed/presented to the Team.</li>
                      <li className="process-list-item-2">Align the team around P&L responsibilities, re-engineering responsibilities (incl. financial controls), and the Mission, Vision, Values, Strengths, and Drivers</li>
                      <li className="process-list-item-2">Review/Raise Capital where necessary (ideally thru operational improvements, divestiture of non-core assets, capital partners)</li>
                      <li className="process-list-item-2">Establish reasonable expectations regarding process improvement initiatives, growth and value expansion plans including timelines</li>
                    </ul>
                    </div>
                  {/* <img src="graphic4-min.png" className="assess-graphic" alt="graphic"/> */}
                  </div>
              <div className="bottom">
                  <br/>
                  <div className="line">
                </div>
                {/* <br/><button type="button" onClick={this.servicesPage.bind(this)} className="btn btn-primary-below">LEARN MORE</button> */}
                  <div className="line-tablet">
                </div>
                  <br/>
                  {/* <div className="lower-text"><text><p className="why-p lower-p"><text className="address-header">Address:</text> 3020 Red Oak Drive  |  Harvard, Illinois&nbsp;&nbsp;60033&nbsp;&nbsp;<text className="address-header">Telephone: </text> (815) 245-4383<br/><text className="address-header">Web: </text><a className="why-p lower-p" href="https://korslinhce.com">www.korslinhce.com</a>&nbsp;&nbsp;  <text className="address-header">Email: </text><a className="why-p lower-p" href="mailto:bkorslin@korslinhce.com">bkorslin@korslinhce.com</a></p></text></div> */}
                  <br/>
              </div>
                  {/* <div className="why-p-bottom">We apply decades of healthcare industry experience to address the many <br/>challenges facing our clients in today’s world.</div><br/> */}
                  <div className="social-media-icons">
                      <a target="_blank" href="http://linkedin.com/in/william-korslin-7a211312a/"><img src="linked-in-icon.png" className="social-icon" alt="linked-in"/></a>
                      </div>
                  <br/>
          </div>
          <div id="Contact" className="tabcontent">
          <br/>
            <div className="row-button">
            <div className="col-md-3"></div>
              <div className="col-md-5"><h4 className="paradigm-shift">Contact Us</h4><div className="right-border"></div></div>
              <div className="col-md-4"><button type="button" onClick={this.servicesPage.bind(this)} className="btn-beneath-billboard">ABOUT US</button>
            </div>
            </div>
            <div className="line-5"></div>
              <div className="mobile-home-page-title">Healthcare<br/>Business Advisory</div>
              <button type="button" onClick={this.servicesPage.bind(this)} className="btn btn-primary-mobile">LEARN MORE</button>
              <br/><br/><br/><i className="fa-sharp fa-light fa-angle-right"></i><div className="mobile-under-caption">&nbsp;&nbsp;Learn Even More</div>
              <div className="row-2">
            <div className="why-p">We do this through creative and thoughtful collaboration with our clients, assisting them in evaluating their business and, where necessary, modifying strategies to ensure our clients are positioned to take advantage of opportunities unfolding in the marketplace.  </div><br/>
            <div className="col-md-7 text-left">
            </div>
              <div className="col-md-5 text-right">
                <div className="card-2-steth">
              </div>
              </div>
              <div className="card-2-steth"><br/>
              </div>
            </div>
            <div className="mobile-bottom">         
              <br/>
              <div className="line-3"></div>
              <br/>
              <div className="lower-text-bottom"><text>KorslinHCE applies decades of healthcare industry experience to address the many challenges facing our clients in today’s world.</text></div><br/>
              </div>
              <div className="bottom">
                  <br/>
                  <div className="line" id="line">
                </div>
                <br/>
                {/* <button type="button" onClick={this.servicesPage.bind(this)} className="btn btn-primary-below">LEARN MORE</button> */}
                  <div className="line-tablet">
                </div>
                  <br/>
                  {/* <div className="lower-text"><text><p className="why-p lower-p"><text className="address-header">Address:</text> 3020 Red Oak Drive  |  Harvard, Illinois&nbsp;&nbsp;60033&nbsp;&nbsp;<text className="address-header">Telephone: </text> (815) 245-4383<br/><text className="address-header">Web: </text><a className="why-p lower-p" href="https://korslinhce.com">www.korslinhce.com</a>&nbsp;&nbsp;  <text className="address-header">Email: </text><a className="why-p lower-p" href="mailto:bkorslin@korslinhce.com">bkorslin@korslinhce.com</a></p></text></div> */}
                  <br/>
              </div>
                  <div className="row-contact-us-page" id="row-contact-us-page">
                    <div className="col-md-4"><h11 className="contact-title-opening">Phone</h11><br/><text><br/><br/><br/><text className="hours-of-operation">Hours of Operation</text></text><br/><text className="hours-of-operation">Monday - Friday</text><br/><text className="hours-of-operation">9am CST to 4pm CST</text><br/><text className="hours-of-operation">+1 [815] 245-4383</text></div>
                    <div className="col-md-8">
                    <div className="form-container" id="large-screen-form">
`                    <h11 className="contact-title-opening">Email Us<text className="us-text"></text></h11><h11 className="contact-title-opening-punctuation"></h11><br/><br/>
                          <div className="form-div">
                            <label for="email"><text className="us-text-label-2">Email Address</text></label>
                            <input type="email" ref={this.emailRef} className="form-control" id="email"/><br/>
                            <label for="first-name"><text className="us-text-label-2">First Name</text></label>
                            <input type="input" ref={this.fNameRef} className="form-control" id="first-name"/><br/>
                            <label for="last-name"><text className="us-text-label-2">Last Name</text></label>
                            <input type="input" ref={this.lnameRef} className="form-control" id="last-name"/><br/>
                            <label for="company"><text className="us-text-label-2">Company Name</text></label>
                            <input type="input" ref={this.companyRef} className="form-control" id="company"/><br/>
                            <label for="phone"><text className="us-text-label-2">Phone Number</text></label>
                            <input type="tel" ref={this.phoneRef} className="form-control" id="phone"/><br/></div>
                            <label for="message"><text className="us-text-label-2" id="comments">Additional Comments</text></label><br/>
                            <textarea rows="5" ref={this.messageRef} cols="82" className="text-area" id="message"></textarea>
                            <div className="button-div"><br/>
                            <button type="button" onClick={this.formSubmit.bind(this)} className="btn btn-primary-submit">SUBMIT</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<text onClick={this.formClear.bind(this)} className="form-clear">CLEAR</text><br/><br/><br/><br/><br/>
                            </div></div>
                    </div>
                  </div>
                  {/* <div className="why-p-bottom">We apply decades of healthcare industry experience to address the many <br/>challenges facing our clients in today’s world.</div><br/> */}
                  <div className="bottom">
                  <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                  <div className="line">
                </div>
                <br/>
                      <div className="social-media-icons">
                      <a target="_blank" href="http://linkedin.com/in/william-korslin-7a211312a/"><img src="linked-in-icon.png" className="social-icon" alt="linked-in"/></a>
                      </div>
                  <br/>
          </div>
                  <br/>
          </div>
          <div className="check-img">
            <img className="check" src="check.png" alt="check-mark"/>
          </div>
          {/* <div id="myNav" className="mobile-nav">
            <a href="#" className="closebtn" onclick={this.closeMobileNav.bind(this)}>&times;</a>
            <div class="overlay-content">
              <a href="#">About</a>
              <a href="#">Services</a>
              <a href="#">Clients</a>
              <a href="#">Contact</a>
            </div>
          </div> */}
          </div>
   
       
    );
  }
}

export default Home;

