// Dependencies
import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <footer><br/>
    <div className="footer-container">
        <img src="blue-2.jpg" className="blue" alt="background"/>
        <div className="bottom-left">
        <div className="space"></div>
        <div className="footer-magazine-border"></div>
        <div className="footer-magazine-border-2"></div>
        <img className="hcelogo-footer" src="papa-logo.png" alt="HCE Logo"/><br/>

        <text className="footer-text">&nbsp;Consulting</text><br/><br/><br/>
        <div className="footer-text-bold">3020 RED OAK DR<br/><text className="city-state">HARVARD, IL</text></div><br/>
        <text className="footer-text-copyright">&#169;2024 khce-consulting.com</text>

      </div>
      </div>
    </footer>
  );
}

export default Footer;
