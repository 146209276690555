import React, { Component } from 'react';
// import Footer from "./components/Footer";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Parent from "./Components/parent";
import Footer from "./Components/Footer";

import './App.css';

function App() {
  return (
    <Router>
      <div>
      <Navbar/>
      <Parent/>
      <Footer/>
      </div>
    </Router>
  );
}

export default App;
