import React, { Component, createRef } from "react";
import HyperModal from 'react-hyper-modal';
// import instance from "../../api/axios";
import axios from "axios";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./form.css";
// const sql = require('mssql');

class Form extends Component {
  // Component's constructor.
  constructor(props) {
    // super(props) called to ensure that this.props is undefined in this constructor.
    super(props);
    this.fNameRef = createRef()
    this.lnameRef = createRef()
    this.emailRef = createRef()
    this.companyRef = createRef()
    this.phoneRef = createRef()
    this.messageRef = createRef()

    // Setting the state for this component with empty date, time, email, and password keys.
  this.state = {
    firstName: "",
    lastName: "",
    email: "",
    isModalOpen: false
    // password: "",
    // confirm: ""
  };
}

formSubmit(event) {
  event.preventDefault();
  if (this.emailRef.current.value == "") {
    alert("Please enter a valid Email Address.")
     }   else if (this.fNameRef.current.value == "") {
    alert("Plesse enter your First Name.")
     }  else if (this.lnameRef.current.value == "") {
    alert("Please enter your Last Name.") 
     }  else if (this.companyRef.current.value == "") {
    alert("Please enter your Company Name.") 
    } else {
      console.log("why is this finally working?");
      axios({
        method: "POST", 
        url: "/sub", 
        data: {
          name: this.fNameRef.current.value +" "+ this.lnameRef.current.value,   
          email: this.emailRef.current.value,  
          message: this.message.current.value
        }}).then(function (res) {
        alert("Thank you for your inquiry!  Korslin Consulting will reach out to you shortly...")
        window.location.href="/";
      })
      .catch(function (error) {
        alert(error.toJSON());
      });
}
};



  render() {
    const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
      }
    };
    return (
      <div><br/>
        <div className="form-container"><br/>
        <div className="card-form">
        <h11 className="contact-title">CONTACT<text className="us-text">US</text></h11><br/><br/>
        <div className="row-6">
          <div className="col-md-6">
          <label for="email">Email address:</label>
          <input type="email" ref={this.emailRef} className="form-control" id="email"/><br/>
          <label for="first-name">First Name:</label>
          <input type="input" ref={this.fNameRef} className="form-control" id="first-name"/><br/>
          <label for="last-name">Last Name:</label>
          <input type="input" ref={this.lnameRef} className="form-control" id="last-name"/><br/>
          <label for="company">Company Name:</label>
          <input type="input" ref={this.companyRef} className="form-control" id="company"/><br/>
          <label for="phone">Phone No.:</label>
          <input type="tel" ref={this.phoneRef} className="form-control" id="phone"/><br/><br/>
        </div>
        <div className="col-md-6">
          <label for="message">Your Message for Bill:</label>
          <textarea rows="10" ref={this.messageRef} cols="50" id="message"></textarea><br/>
          <button type="button" onClick={this.formSubmit.bind(this)} className="btn btn-primary">SUBMIT</button><br/><br/>

        </div>
        </div>
        <div className="row-7">
          <div className="col-md-12">
          </div></div>
        </div>

        </div><br/><br/>
      </div>
    );
  }
}


export default Form;
