import React, { Component, createRef } from "react";
import HyperModal from 'react-hyper-modal';
// import instance from "../../api/axios";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./team.css";
// const sql = require('mssql');

class Team extends Component {
  // Component's constructor.
  constructor(props) {
    // super(props) called to ensure that this.props is undefined in this constructor.
    super(props);
    this.fnameRef = createRef()
    this.lnameRef = createRef()
    this.emailRef = createRef()
    this.passRef = createRef()
    this.confRef = createRef()
    // Setting the state for this component with empty date, time, email, and password keys.
  this.state = {
    firstName: "",
    lastName: "",
    email: "",
    isModalOpen: false
    // password: "",
    // confirm: ""
  };
};

componentDidMount () {
  document.getElementById("read-more-id").style.display = "none";
  document.getElementById("filler-container").style.display = "none";
  // document.getElementById("read-less-id").style.display = "block";

}

openFullTab (event)  {
  // document.getElementById("resume-card").style.height = "750px";
  // document.getElementById("resume-tab").style.display = "none";
  document.getElementById("read-more-id").style.display = "block";
  document.getElementById("filler-container").style.display = "block";
  document.getElementById("read-more-button-id").style.display = "none";
  window.scrollTo(0, 0)
  // document.getElementById("filler-container").style.height = "50px";
};

closeFullTab (event)  {
  // document.getElementById("resume-card").style.height = "750px";
  // document.getElementById("resume-tab").style.display = "none";
  document.getElementById("read-more-id").style.transition = "0.5s";
  document.getElementById("read-more-id").style.display = "none";
  document.getElementById("read-more-button-id").style.display = "block";

  document.getElementById("filler-container").style.display = "none";

  // document.getElementById("read-more-button-id").style.display = "none";

  // document.getElementById("filler-container").style.height = "50px";
};



  render() {
    const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
      }
    };
    return (
      <div>
        <img src="hancock.jpg" className="background-img-team" alt="background"/>
        <img src="hancock-mobile.jpg" className="background-img-team-mobile" alt="background"/>
        <div className="container-team">
        <h3 className="services-title">Meet The Team</h3>

        <div className="bio-row">
        <div className="col-xs-1 col-sm-1 col-md-1">
          </div>
        <div className="col-xs-2 col-sm-2 col-md-2" id="border-removal">
          <div className="bio-img-container">
            <img src="bk.jpg" className="bios-img" alt="founder"/>
          </div>    
          </div>
          <div className="col-xs-5 col-sm-5 col-md-5" >
          <div className="bio-img-title-container"><br/>
            <div className="biography-name">Bill Korslin</div>
            <div className="position-biography-founder">FOUNDER & CEO</div>
            <div className="bio-line"></div>
            <div href="mailto:bkorslin@korslinhce.com" className="phone-label">bkorslin@korslinhce.com</div>
            <div className="bio-line"></div>
          </div>
          </div>
          <div className="col-xs-4 col-sm-4 col-md-4">
          </div>
          <div className="write-up-row">
            <div className="col-xs-1 col-sm-1 col-md-1"></div>
            <div className="col-xs-10 col-sm-10 col-md-10 padding palet"><div className="actual-write-up-row"><div className="bio-content">William (Bill) R. Korslin is an accomplished C-suite Executive with an impressive 40-year career
in healthcare, that includes advancement into increasingly demanding leadership roles based
on delivering unprecedented revenue growth and high-impact results for companies, such as
AHSC, NME, ARA, MPAN, and Huron.<br/><div onClick={this.openFullTab.bind(this)} id="read-more-button-id" className="read-more-button"><br/>[ Read More ]</div><div id="read-more-id" className="read-more"><br/>
With a solid history of turning money-losers into profit-makers, he is as very much at home,
sharply reversing trajectories of underperforming operations. Recent successes include
increasing YoY 2018 – 2019 EBITDA 48% from $37M to $55M while growing revenue 18% from

$221M to $260M for a previously struggling organization.<br/><br/>
Most recently, at CCS Medical, Bill made key contributions as interim CEO, directing the turnaround of a $300M
company with 4 business lines, 700+ employees, serving 100,000+ patients. Ultimately, he grew revenue 18% from
$221M to $260M, while improving EBIT 245% from $7M to $26M YoY 2018 to 2019. Subsequently, he transitioned to
become their permanent CEO and joined CCS Board of Directors in January 2020.<br/><br/>
Previously, Bill served as a Chief Innovation Officer for Gordian Medical, Inc. where he was a key member of the
executive management team that produced more than $200M in revenues from sales to 20,000+ patients in 6000
PA/LTC facilities. At his direction, the Company expanded its product lines by adding specialty medical supplies and a
state-of-the-art research lab to develop advanced bedside diagnostic tests for skin conditions commonly presented in
older adults.<br/><br/>  Prior to this, Bill started up Centrad Healthcare, LLC and grew it to become a leading provider of revenue cycle
management solutions and specialty medical products with $60M in annual revenue. 
By leveraging his RCM and PA/LTC
market expertise, he achieved CAGR of 15% to $58M from 2005 to 2015. Additionally, he completed 10 acquisitions,
ranging from $1M to $30M over a 4-year period.<br/><br/>  As Chief Executive Officer (CEO) for American Pharmaceutical Services Inc. (APS), he advanced through the ranks to
provide strategic leadership building the 5th largest institutional Rx in the US with $360M+ revenue and 1800
employees serving, 4000 LTC facilities and 400,000 patients throughout the US.<br/><br/>
Bill launched his career upon obtaining his Bachelor of Science degree in Administrative Sciences from Southern Illinois
University. Early on, he held various executive leadership and general management roles with American Hospital Supply
Corp. and National Medical Homecare Division of National Medical Enterprises. Bill also served as Sr. Director, Business
Advisory for Huron Consulting Group from 2018 to 2020.<br/><br/>
Also noteworthy, Bill has been the recipient of several prestigious awards, including APS’s Outstanding Sales
Achievement (2 years) and APS’s Presidents Award. As well, for his contributions, he earned Living Centers of America
Chairman’s Award and was inducted into Harvard High School, Harvard Education Foundation Hall of Fame.<br/><br/>
In summary, William R. Korslin is a consummate executive and exceptional business leader who is passionate about
improving healthcare organizations. He brings game-changing perspectives to the table due to his experience in
strategy development and business transformations. Bill is a proven industry thought leader and difference-maker who
leads large, nationwide healthcare corporations to deliver concrete results.<div onClick={this.closeFullTab.bind(this)} id="read-less-button-id" className="read-more-button"><br/>[ Read Less ]</div></div>

<br/></div></div>
            <div className="col-xs-1 col-sm-1 col-md-1"></div>
          </div>
          </div>
        <div className="filler-container" id="filler-container"></div>
        </div></div></div>
  )}
}


export default Team;
