import React, { Component, createRef } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./process.css";

class Services extends Component {
  // Component's constructor.
  constructor(props) {
    // super(props) called to ensure that this.props is undefined in this constructor.
    super(props);
    this.resetEmailRef = createRef()

    // Setting the state for this component with empty date, time, email, and password keys.
  this.state = {
    firstName: "",
    lastName: "",
    email: ""
    // password: "",
    // confirm: ""
  };
}

// Function setting state of the email key.
  submitReset(event) {
    event.preventDefault();
      if (this.resetEmailRef.current.value == "") {
        alert("Please enter your email address.")
      } else {
      alert("We have sent a password re-set link to the following email address:  " + this.resetEmailRef.current.value);
      window.location.href = "/";
  }};

  submitBilling() {
    document.getElementById("one").style.display = "none";
    document.getElementById("two").style.display = "none";
    document.getElementById("three").style.display = "block";
    document.getElementById("two-description").style.display = "none";
    document.getElementById("three-description").style.display = "block";
    document.getElementById("form-registration").style.display = "none";
    document.getElementById("billing-form").style.display = "none";
    document.getElementById("confirmation-form").style.display = "block";
  };

  confirm() {
    document.getElementById("one").style.display = "none";
    document.getElementById("two").style.display = "none";
    document.getElementById("three").style.display = "none";
    document.getElementById("four").style.display = "block";
    document.getElementById("two-description").style.display = "none";
    document.getElementById("three-description").style.display = "none";
    document.getElementById("form-registration").style.display = "none";
    document.getElementById("billing-form").style.display = "none";
    document.getElementById("confirmation-form").style.display = "none";

  };

  // Function setting the state of the date key.
  onUpdate(dateChosen) {
    this.setState({
      date: dateChosen
    });
  };

  // Function setting the state of the time key.
  onTimeUpdate(time) {
    this.setState({
      time: time
    })
  };

  // Function ensuring that the component mounts with the updated state key value pairs.
  componentDidMount() {

  };

  returnToContact() {
    document.getElementById("form-registration").style.display = "block";
    document.getElementById("one").style.display = "block";
    document.getElementById("two").style.display = "none";
    document.getElementById("one-description").style.display = "block";
    document.getElementById("two-description").style.display = "none";
    document.getElementById("three-description").style.display = "none";
    document.getElementById("three").style.display = "none";
    document.getElementById("four").style.display = "none";
    document.getElementById("billing-form").style.display = "none";
    document.getElementById("confirmation-form").style.display = "none";
  }

  returnToBilling() {
    document.getElementById("billing-form").style.display = "block";
    document.getElementById("form-registration").style.display = "none";
    document.getElementById("one").style.display = "none";
    document.getElementById("two").style.display = "block";
    document.getElementById("one-description").style.display = "none";
    document.getElementById("two-description").style.display = "block";
    document.getElementById("three-description").style.display = "none";
    document.getElementById("three").style.display = "none";
    document.getElementById("four").style.display = "none";
    document.getElementById("confirmation-form").style.display = "none";
  }


  render() {
    return (
      <div>
      <img src="fountain.jpg" className="background-img" alt="background"/><br/><br/><br/><br/>
      <img src="fountain-mobile.jpg" className="background-img-mobile" alt="background"/><br/><br/><br/><br/>

      <div className="container-process">
      <h3 className="process-title">The Assessment Process</h3>
      <div className="row-100">
        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
      <ul className="process-list">
        <li className="process-list-item">Understand the Company's financial health/position (overall & broken down by business unit)</li>
        <li className="process-list-item">Understand the Company's value proposition (specifically, how it's measured) and core competencies (i.e., its business model, processes, systems and people)</li>
        <li className="process-list-item">Understand the Company's risks & ecosystem (including risk mitigation strategies such as diversification and projected trends of business units, manufacturers, distributors, competitors, customers, payers)</li>
        <li className="process-list-item">Review Strategic, Operational, and Financial Plan; Mission; and Vision (individually)</li>
        <li className="process-list-item">Understand the Company's technology development plan and corporate governance</li>
        </ul>
</div>
<div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
<ul className="process-list-2" start="6">
        <li className="process-list-item-2">Review to Understand the Company's personnel & culture development initiatives (i.e., Mission, Vision, Values, Strengths, and Drivers)</li>
        <li className="process-list-item-2">Understand the Strategic, Operations, and Financial Plan and how they have been reviewed/presented to the Team.</li>
        <li className="process-list-item-2">Align the team around P&L responsibilities, re-engineering responsibilities (incl. financial controls), and the Mission, Vision, Values, Strengths, and Drivers</li>
        <li className="process-list-item-2">Review/Raise Capital where necessary (ideally thru operational improvements, divestiture of non-core assets, capital partners)</li>
        <li className="process-list-item-2">Establish reasonable expectations regarding process improvement initiatives, growth and value expansion plans including timelines</li>
      </ul>
      </div>
     {/* <img src="graphic4-min.png" className="assess-graphic" alt="graphic"/> */}
     </div>
     <div className="row-123">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <ul className="process-list">
        <li className="process-list-item">Understand the Company's financial health/position (overall & broken down by business unit)</li>
        <li className="process-list-item">Understand the Company's value proposition (specifically, how it's measured) and core competencies (i.e., its business model, processes, systems and people)</li>
        <li className="process-list-item">Understand the Company's risks & ecosystem (including risk mitigation strategies such as diversification and projected trends of business units, manufacturers, distributors, competitors, customers, payers)</li>
        <li className="process-list-item">Review Strategic, Operational, and Financial Plan; Mission; and Vision (individually)</li>
        <li className="process-list-item">Understand the Company's technology development plan and corporate governance</li>
        <li className="process-list-item">Review to Understand the Company's personnel & culture development initiatives (i.e., Mission, Vision, Values, Strengths, and Drivers)</li>
        <li className="process-list-item">Understand the Strategic, Operations, and Financial Plan and how they have been reviewed/presented to the Team.</li>
        <li className="process-list-item">Align the team around P&L responsibilities, re-engineering responsibilities (incl. financial controls), and the Mission, Vision, Values, Strengths, and Drivers</li>
        <li className="process-list-item">Review/Raise Capital where necessary (ideally thru operational improvements, divestiture of non-core assets, capital partners)</li>
        <li className="process-list-item">Establish reasonable expectations regarding process improvement initiatives, growth and value expansion plans including timelines</li>
      </ul>
      </div>
     {/* <img src="graphic4-min.png" className="assess-graphic" alt="graphic"/> */}
     </div>
     </div>
     </div>
    //  </div>
    // </div>
    );
  }
}


export default Services;
