// import React from "react";
import React, { Component, createRef } from "react";
import axios from "axios";
import "./Navbar.css";
import { BACKEND_URI } from '../../config';
import emailjs from '@emailjs/browser';


class Navbar extends Component {
  // Component's constructor.
  constructor(props) {
    // super(props) called to ensure that this.props is undefined in this constructor.
    super(props);
    this.fNameRef = createRef()
    this.lnameRef = createRef()
    this.emailRef = createRef()
    this.companyRef = createRef()
    this.phoneRef = createRef()
    this.messageRef = createRef()
    this.fullNameMobileRef = createRef()
    this.lnameMobileRef = createRef()
    this.emailMobileRef = createRef()
    this.companyMobileRef = createRef()
    this.phoneMobileRef = createRef()
    this.messageMobileRef = createRef()

    // Setting the state for this component with empty date, time, email, and password keys.
  this.state = {


    firstName: "",
    lastName: "",
    email: "",
    isModalOpen: false
    // password: "",
    // confirm: ""
  };
  }

componentDidMount () {
  document.getElementById("arrow").style.display = "none";
};

openMobileNav () {
  document.getElementById("myNav").style.height = "90%";
  document.getElementById("main-mobile-menu").style.display = "block";
  document.getElementById("mobile-contact-form").style.display = "none";
  document.getElementById("white-hamburger").style.display = "none";
}

closeMobileNav () {
  // alert("ha!");
  document.getElementById("myNav").style.height = "0%";
  document.getElementById("white-hamburger").style.display = "block";

}

seeAbout () {
  document.getElementById("dropdown-container").style.display = "block";
  document.getElementById("about").style.display = "none";
  // document.getElementById("up-arrow").style.display = "block";
}

hideAbout () {
  document.getElementById("dropdown-container").style.display = "none";
  document.getElementById("about").style.display = "block";
  document.getElementById("dropdown-container").style.display = "none";
}

mobileContactSelect () {
  // this.closeMobileNav();
  // document.getElementById("mobile-contact-form").style.height = "100%";
  document.getElementById("main-mobile-menu").style.display = "none";
  // document.getElementById("mobile-contact-form").style.transition = "3s";
  document.getElementById("mobile-contact-form").style.display = "block";
  document.getElementById("white-hamburger").style.display = "none";
}


formSubmit () {
  const templateParams = {
    guest: this.fullNameMobileRef.current.value,
    company: this.companyMobileRef.current.value,
    guestEmail: this.emailMobileRef.current.value,
    guestPhone: this.phoneRef.current.value,
    message: this.messageRef.current.value
};

emailjs.send('service_e0ru5vn','template_t1c2qo5', templateParams, 'tk5NryehwfMcAPlRj')
	.then((response) => {
	   console.log('SUCCESS!', response.status, response.text);
     alert("Thank you for sumbitting your inquiry to KHCE.  We will get back to you within 48 business hours.");
     this.emailMobileRef.current.value="";
     this.fullNameMobileRef.current.value="";
     this.lnameRef.current.value="";
     this.companyRef.current.value="";
     this.phoneRef.current.value="";
     this.messageRef.current.value="";
     this.emailMobileRef.current.value="";
     this.fNameMobileRef.current.value="";
     this.lnameMobileRef.current.value="";
     this.companyMobileRef.current.value="";
     this.phoneMobileRef.current.value="";
     this.messageMobileRef.current.value="";
	}, (err) => {
	   console.log('FAILED...', err);
	});
  this.closeContactNav();
}

formClear() {
  this.emailRef.current.value="";
  this.fNameRef.current.value="";
  this.lnameRef.current.value="";
  this.companyRef.current.value="";
  this.phoneRef.current.value="";
  this.messageRef.current.value="";
  this.emailMobileRef.current.value="";
  this.fullNameMobileRef.current.value="";
  this.lnameMobileRef.current.value="";
  this.companyMobileRef.current.value="";
  this.phoneMobileRef.current.value="";
  this.messageMobileRef.current.value="";
  this.fullNameMobileRef.current.value="";
  this.emailMobileRef.current.value="";
  this.companyMobileRef.current.value="";
  this.phoneMobileRef.current.value="";
  this.messageMobileRef.current.value="";
}


formMobileNext () {
  if (this.emailMobileRef.current.value == "") {
    alert("Please enter a valid Email Address.")
     }   else if (this.fNameMobileRef.current.value == "") {
    alert("Plesse enter your First Name.")
     }  else if (this.lnameMobileRef.current.value == "") {
    alert("Please enter your Last Name.") 
     }  else if (this.companyMobileRef.current.value == "") {
    alert("Please enter your Company Name.") 
    } else {
      document.getElementById("initial-mobile-form").style.display = "none";
      document.getElementById("final-mobile-contact").style.display = "block";
    }};

formMobileSubmit () {
  axios({
    method: "POST", 
    url: "/submit", 
    data: {
      name: this.fNameMobileRef.current.value +" "+ this.lnameMobileRef.current.value,   
      email: this.emailMobileRef.current.value,  
      phone:  this.phoneMobileRef.current.value,
      company:  this.companyMobileRef.current.value,
      message: this.messageMobileRef.current.value
    }}).then(function (res) {
    // }}).then(function (res) {
    alert("Thank you for your inquiry!  KORSLINhce will reach out to you shortly...");
    window.scrollTo(0, 0);
    document.getElementById("mobile-contact").style.height = "0";
  })
  .catch(function (error) {
    console.log(error);
    alert("There was an error sending your message.  Please try again later!");
    document.getElementById("arrow").style.display = "none";
    document.getElementById("mobile-contact").style.height = "0";
  });
}

openNav () {
  document.getElementById("mySidenav").style.width = "40%";
  document.getElementById("contact-choice-div").style.display = "block";
  document.getElementById("email-sidenav-div").style.display = "none";
  document.getElementById("arrow").style.display = "block";

};

showEmailForm() {
  this.formClear();
  document.getElementById("mySidenav").style.width = "55%";
  document.getElementById("arrow").style.display = "block";

  document.getElementById("contact-choice-div").style.display = "none";
  document.getElementById("email-sidenav-div").style.display = "block";

}

closeNav () {
  document.getElementById("mySidenav").style.transition = "1s"
  document.getElementById("mySidenav").style.width = "0%";
  document.getElementById("arrow").style.display = "none";
};

closeNavMobile () {
  document.getElementById("mySidenav-mobile-1").style.height = "0%";
  document.getElementById("mobile-hamburger-icon").style.display = "block";
  document.getElementById("second-row-navbar").style.display = "block";

  document.getElementById("mySidenav-mobile-1").style.transition = "1s"
  document.getElementById("second-row-navbar").style.display = "block";
};

closeNavContactMobile () {
  document.getElementById("mobile-contact").style.height = "0";
  document.getElementById("mobile-toggle").style.display = "block";
};

mobileNav() {
  // alert("Why doesn't this navbar opeN?")
  // event.preventDefault();
  // document.getElementById("mobile-toggle").style.display = "none
  // document.getElementById("mobile-contact").style.height = "0";
  // document.getElementById("mySidenav-mobile").style.display = "block";
  // document.getElementById("mySidenav-mobile").style.width = "100%";
  document.getElementById("mySidenav-mobile-1").style.height = "100%";
  document.getElementById("mobile-hamburger-icon").style.display = "none";
  document.getElementById("second-row-navbar").style.display = "none";
};

homePage(event) {
  event.preventDefault();
  // alert("Does this onClick function work?")
  window.location.href="/";
}

visitHome(event) {
  event.preventDefault();
  document.getElementById("mySidenav-mobile-1").style.height = "0%";
  document.getElementById("mySidenav-mobile-1").style.transition = "1s"
  window.location.href="/";
}

visitTeam(event) {
  event.preventDefault();
  document.getElementById("mySidenav-mobile-1").style.height = "0";
  window.location.href="/team";
}

visitServices(event) {
  event.preventDefault();
  document.getElementById("mySidenav-mobile-1").style.height = "0";
  window.location.href="/services";
}

visitExpertise(event) {
  event.preventDefault();
  document.getElementById("mySidenav-mobile-1").style.height = "0";
  window.location.href="/expertise";
}

visitMission(event) {
  event.preventDefault();
  document.getElementById("mySidenav-mobile-1").style.height = "0";
  window.location.href="/mission";
}

visitProcess(event) {
  event.preventDefault();
  document.getElementById("mySidenav-mobile-1").style.height = "0";
  window.location.href="/process";
}

showContactMobile(event) {
  event.preventDefault();
  this.formClear();
  document.getElementById("mobile-toggle").style.display = "block";
  document.getElementById("mySidenav-mobile").style.width = "0";
  document.getElementById("mobile-contact-choice").style.display = "none";
  document.getElementById("email-mobile-div").style.display = "block";
  document.getElementById("initial-mobile-form").style.display = "block";
  document.getElementById("final-mobile-contact").style.display = "none";
  document.getElementById("mobile-contact").style.height = "78%";
}

showEmailFormMobile(event) {
  event.preventDefault();
  this.formClear();
  document.getElementById("mobile-contact-choice").style.display = "none";
  document.getElementById("mobile-contact").style.height = "78%";
  document.getElementById("email-mobile-div").style.display = "block";
  document.getElementById("email-sidenav-div").style.display = "block";
  document.getElementById("initial-mobile-form").style.display = "block";


}

closeContactNav () {
  document.getElementById("mobile-contact-form").style.transition = "1s"
  document.getElementById("mobile-contact-form").style.height = "0%";
  document.getElementById("white-hamburger").style.display = "block";
  this.formClear();
  // document.getElementById("white-hamburger").style.display = "block";
}


render () {
    return (
      <nav class="navbar navbar-default fixed-top navbar-absolute-top bg-light transparent">
        <div className="navbar-container">
        </div>
      <div class="container-fluid fixed-top">
      <ul class="nav navbar-nav">
        <li><a href="/">HOME</a></li>
        <li><a href="/expertise">EXPERTISE</a></li>
        <li><a href="/services">SERVICES</a></li>
        <li><a href="/process">PROCESS</a></li>
      </ul>
      </div>
        <img className="main-header-logo" src="papa-logo.png" onClick={this.homePage.bind(this)} alt="logo"/>
        {/* <img className="main-header-logo" src="png5.png" onClick={this.homePage.bind(this)} alt="logo"/> */}
        {/* <div id="mobile-hamburger-icon"><img className="hamburger-icon-mobile" onClick={this.mobileNav.bind(this)} src="hamburger.png" alt="mobile-menu"/></div> */}
        <div className="white-hamburger" id="white-hamburger" onClick={this.openMobileNav.bind(this)}><i class="fa-sharp fa-solid fa-grip-lines"></i></div>
      <br/>
      <div className="row row-navbar" id="second-row-navbar">
        <div onClick={this.visitTeam.bind(this)} className="col-md-2" id="about-us-button">About Us</div>
        <div onClick={this.openNav.bind(this)} className="col-md-1" id="top-menu-contact-us">Contact</div>
        <div className="col-md-9"></div>
      </div>
      <br/>

{/* main menu mobile navigation */}

  <div id="mySidenav-mobile-1" className="sidenav-mobile"><br/><br/><br/>
  <a className="no-bottom-border" href="#"><text onClick={this.visitHome.bind(this)}></text>
  <img className="hcelogo-mobile" src="papa-logo.png" alt="HCE Logo"/>
  {/* <img className="hcelogo-mobile" src="logo-hce.png" alt="HCE Logo"/> */}

  </a><br/>
    <a href="#"><text onClick={this.visitHome.bind(this)}>Home</text></a><br/>
    <div id="about">
    <a onClick={this.seeAbout.bind(this)}>About</a>
    </div><br/>
      <div id="dropdown-container">
        <a onClick={this.hideAbout.bind(this)}>About</a><br/>
        <a onClick={this.visitMission.bind(this)}>Mission & Values</a>
        <a onClick={this.visitTeam.bind(this)}>Team&nbsp;&nbsp;|</a><br/><br/>
      </div>
    <a href="#"><text onClick={this.visitExpertise.bind(this)}>Expertise</text></a><br/>  
    <a href="#"><text onClick={this.visitServices.bind(this)}>Services</text></a><br/>
    <a href="#"><text onClick={this.visitProcess.bind(this)}>Process</text></a><br/>
    <a className="no-bottom-border" href="#"><text onClick={this.showContactMobile.bind(this)}>Contact</text></a>
  <div className="linkedIn">
    <div className="sidenav-line"></div>
    <br/><br/>
    <a className="no-bottom-border" href="https://www.linkedin.com/in/william-korslin-7a211312a/" target="_blank"><img src="linked-in-icon.png" className="icon"/></a>
  </div><br/>
  <div onClick={this.closeMobileNav.bind(this)}><i className="fa-thin fa-angle-down"></i></div>
  </div>

 {/* mobile contact form */}

  <div id="mobile-contact" className="mobile-contact"><br/>
  <h4><text className="korslin-sidenav-text-2">Contact</text><text className="us-2">Us</text></h4><br/>
  {/* <div className="sidenav-line"></div><br/> */}
  <a href="javascript:void(0)" className="closebtn" onClick={this.closeMobileNav.bind(this)}>&times;</a>
  <div className="row-12" id="mobile-contact-choice">
      <div className="col-xs-6">
        <a href="tel:18152454384"><div className="card-contact-mobile"><br/>
        <a href="tel:18152454384"><span className="glyphicon glyphicon-phone-alt"></span></a>
        <h5 className="contact">Phone</h5>
        </div></a>
      </div>
      <div className="col-xs-6">
      <div onClick={this.showEmailFormMobile.bind(this)} className="card-contact-mobile-2"><br/>
        <a onClick={this.showEmailFormMobile.bind(this)} ><span className="glyphicon glyphicon-envelope"></span></a>

        <h5 className="contact">Email</h5>
      </div>
      </div>
    </div>

    <div className="email-sidenav-div" id="email-mobile-div" style={{display: 'none'}}>
    <div className="form-container-mobile">
        <div className="form-div-mobile" id="initial-mobile-form" style={{display: 'none'}}>
          <label className="first-word" for="email">EMAIL<text className="us-text-label"> ADDRESS:</text></label>
          <input type="email" ref={this.emailMobileRef} className="form-control" id="email"/><br/>
          <label className="first-word" for="first-name">FIRST<text className="us-text-label"> NAME:</text></label>
          <input type="input" ref={this.fNameMobileRef} className="form-control" id="first-name"/><br/>
          <label className="first-word" for="last-name">LAST<text className="us-text-label"> NAME:</text></label>
          <input type="input" ref={this.lnameMobileRef} className="form-control" id="last-name"/><br/>
          <label className="first-word" for="company">COMPANY<text className="us-text-label"> NAME:</text></label>
          <input type="input" ref={this.companyMobileRef} pattern="[0-9]{3} [0-9]{3}-[0-9]{4}" className="form-control" id="company"/><br/>
          <label className="first-word" for="phone">PHONE<text className="us-text-label"> NUMBER:</text></label>
          <input type="tel" ref={this.phoneMobileRef} className="form-control" id="phone"/><br/>
          <button type="button" onClick={this.formClear.bind(this)} className="btn btn-primary">CLEAR</button>&nbsp;&nbsp;<button type="button" onClick={this.formMobileNext.bind(this)} className="btn btn-primary clear">NEXT</button><br/><br/>
        </div></div></div>
          <div className="form-div" id="final-mobile-contact" style={{display: 'none'}}>
           <label className="first-word" for="message">ADDITIONAL<text className="us-text-label">COMMENTS:</text></label><br/>
           <textarea rows="5" ref={this.messageMobileRef} cols="30" id="message"></textarea><br/>
           <text onClick={this.formClear.bind(this)} className="from-clear">CLEAR</text><button type="button" onClick={this.formMobileSubmit.bind(this)} className="btn btn-primary">SUBMIT</button><br/><br/>
          </div></div>

  {/* Sidenav for big screen visitors  */}

  <div id="mySidenav" className="sidenav">
  <i onClick={this.closeNav.bind(this)} id="arrow" className="las la-angle-right"></i>
  <div id="contact-choice-div">
  <h11 className="contact-title-opening">CONTACT<text className="us-text">Us</text></h11><h11 className="contact-title-opening-punctuation"></h11><br/><br/><br/>
    <div className="row-12">
      <div className="col-md-6">
        <div  className="card-contact">
        <img src="phone.jpeg" className="phone-img" alt="phone"/>
        <div className="contact-us-option">Phone</div>
        </div>
      </div>
      <div className="col-md-6 right">
      <div onClick={this.showEmailForm.bind(this)} className="card-contact">
        <img src="email.png" className="email-icon" alt="email"/>
        <div className="contact-us-option">Email</div>
      </div>
      </div>
    </div>
    <div className="reach-out-method-question"><h5>How would you like to <br/>reach us?</h5></div>
    </div>
    <div className="email-sidenav-div" id="email-sidenav-div" style={{display: 'none'}}>

  <div className="form-container" id="large-screen-form">
  <h11 className="contact-title-opening">CONTACT<text className="us-text">Us</text></h11><h11 className="contact-title-opening-punctuation"></h11><br/><br/>
        <div className="form-div">
          <label for="email"><text className="us-text-label-2">Email Address</text></label>
          <input type="email" ref={this.emailRef} className="form-control" id="email"/><br/>
          <label for="first-name"><text className="us-text-label-2">First Name</text></label>
          <input type="input" ref={this.fNameRef} className="form-control" id="first-name"/><br/>
          <label for="last-name"><text className="us-text-label-2">Last Name</text></label>
          <input type="input" ref={this.lnameRef} className="form-control" id="last-name"/><br/>
          <label for="company"><text className="us-text-label-2">Company Name</text></label>
          <input type="input" ref={this.companyRef} className="form-control" id="company"/><br/>
          <label for="phone"><text className="us-text-label-2">Phone Number</text></label>
          <input type="tel" ref={this.phoneRef} className="form-control" id="phone"/><br/></div>
          <label for="message"><text className="us-text-label-2">Additional Comments</text></label>
          <textarea rows="5" ref={this.messageRef} cols="500" className="text-area" id="message"></textarea><br/>
          <div className="button-div"><br/>
          <button type="button" onClick={this.formSubmit.bind(this)} className="btn btn-primary-submit">SUBMIT</button>&nbsp;&nbsp;&nbsp;&nbsp;<text onClick={this.formClear.bind(this)} className="form-clear">CLEAR</text><br/><br/>
          </div></div></div></div>
            <div id="myNav" className="mobile-nav">
              {/* <a href="#" className="closebtn" id="closeBtn" onClick={this.closeMobileNav.bind(this)}>&times;</a> */}
              <div className="nav-magazine-border"></div><br/>
              <div className="nav-magazine-line"></div>
              <img className="hcelogo-home-mobile-nav" src="papa-logo.png" alt="HCE Logo"/><br/><br/><br/>
              <text className="consulting-class">Consulting</text><br/><br/><br/><br/><br/>
              <div id="main-mobile-menu" class="overlay-content">
              <a href="#" onClick={this.closeMobileNav.bind(this)}><div className="col-xs-9">Home</div><div className="col-xs-3"><i className="fa fa-chevron-right"></i></div></a>
                <a href="#" onClick={this.closeMobileNav.bind(this)}><div className="col-xs-9">Expertise</div><div className="col-xs-3"><i className="fa fa-chevron-right"></i></div></a>
                <a href="#" onClick={this.closeMobileNav.bind(this)}><div className="col-xs-9">Services</div><div className="col-xs-3"><i className="fa fa-chevron-right"></i></div></a>
                <a href="#" onClick={this.closeMobileNav.bind(this)}><div className="col-xs-9">Process</div><div className="col-xs-3"><i className="fa fa-chevron-right"></i></div></a>
                <a href="#" onClick={this.mobileContactSelect.bind(this)}><div className="col-xs-9">Contact</div><div className="col-xs-3"><i className="fa fa-chevron-right"></i></div></a>
                {/* <a onClick={this.closeMobileNav.bind(this)}><i class="fa-solid fa-chevron-up"></i></a> */}

              </div>
              <div id="mobile-contact" className="mobile-contact"><br/>
  <h4><text className="korslin-sidenav-text-2">Contact</text><text className="us-2">Us</text></h4><br/>
  {/* <div className="sidenav-line"></div><br/> */}
  <a href="javascript:void(0)" className="closebtn" onClick={this.closeMobileNav.bind(this)}>&times;</a>
  <div className="row-12" id="mobile-contact-choice">
      <div className="col-xs-6">
        <a href="tel:18152454384"><div className="card-contact-mobile"><br/>
        <a href="tel:18152454384"><span className="glyphicon glyphicon-phone-alt"></span></a>
        <h5 className="contact">Phone</h5>
        </div></a>
      </div>
      <div className="col-xs-6">
      <div onClick={this.showEmailFormMobile.bind(this)} className="card-contact-mobile-2"><br/>
        <a onClick={this.showEmailFormMobile.bind(this)} ><span className="glyphicon glyphicon-envelope"></span></a>

        <h5 className="contact">Email</h5>
      </div>
      </div>
    </div>

    <div className="email-sidenav-div" id="email-mobile-div" style={{display: 'none'}}>
    <div className="form-container-mobile">
        <div className="form-div-mobile" id="initial-mobile-form" style={{display: 'none'}}>
          <label className="first-word" for="email">EMAIL<text className="us-text-label"> ADDRESS:</text></label>
          <input type="email" ref={this.emailMobileRef} className="form-control" id="email"/><br/>
          <label className="first-word" for="first-name">FIRST<text className="us-text-label"> NAME:</text></label>
          <input type="input" ref={this.fNameMobileRef} className="form-control" id="first-name"/><br/>
          <label className="first-word" for="last-name">LAST<text className="us-text-label"> NAME:</text></label>
          <input type="input" ref={this.lnameMobileRef} className="form-control" id="last-name"/><br/>
          <label className="first-word" for="company">COMPANY<text className="us-text-label"> NAME:</text></label>
          <input type="input" ref={this.companyMobileRef} pattern="[0-9]{3} [0-9]{3}-[0-9]{4}" className="form-control" id="company"/><br/>
          <label className="first-word" for="phone">PHONE<text className="us-text-label"> NUMBER:</text></label>
          <input type="tel" ref={this.phoneMobileRef} className="form-control" id="phone"/><br/>
          <button type="button" onClick={this.formClear.bind(this)} className="btn btn-primary">CLEAR</button>&nbsp;&nbsp;<button type="button" onClick={this.formMobileNext.bind(this)} className="btn btn-primary clear">NEXT</button><br/><br/>
        </div></div></div>
          <div className="form-div" id="final-mobile-contact" style={{display: 'none'}}>
           <label className="first-word" for="message">ADDITIONAL<text className="us-text-label">COMMENTS:</text></label><br/>
           <textarea rows="5" ref={this.messageMobileRef} cols="30" id="message"></textarea><br/>
           <text onClick={this.formClear.bind(this)} className="from-clear">CLEAR</text><button type="button" onClick={this.formMobileSubmit.bind(this)} className="btn btn-primary">SUBMIT</button><br/><br/>
          </div></div>
              <br/>
              <div className="up-arrow-div"><a onClick={this.closeMobileNav.bind(this)}><i class="fa-thin fa-chevron-up"></i></a></div>

                <div className="social-media-icons">
                  <a target="_blank" href="http://linkedin.com/in/william-korslin-7a211312a/"><img src="linked-in-icon.png" className="social-icon" alt="linked-in"/></a>
                </div>
            </div>
            <div id="mobile-contact-form" className="mobile-contact-form">
              {/* <a href="#" className="closebtn" id="closeBtn" onClick={this.closeContactNav.bind(this)}>&times;</a>
              <img className="hcelogo-home-mobile-nav" src="papa-logo.png" alt="HCE Logo"/><br/><br/><br/> */}
              <text className="consulting-class">We'd like to hear from you...</text><br/><br/>
              <div class="overlay-content">
                  <label for="email">email address</label>
                  <input type="email" ref={this.emailMobileRef} id="email" name="email"/>
                  <label for="email">Full Name</label>
                  <input type="input" ref={this.fullNameMobileRef} id="full-name" name="full name"/>
                  <label for="company">Company</label>
                  <input type="input" ref={this.companyMobileRef} id="company" name="company"/>
                  <label for="phone">Phone Number</label>
                  <input type="input" ref={this.phoneRef} id="phone-number" name="phone-number"/>
                  <label for="notes">Additional Notes</label>
                  <textarea name="textarea" ref={this.messageRef} className="additional-notes" rows="2" cols="42"></textarea>
              </div><br/>
              <button type="button" onClick={this.formSubmit.bind(this)} className="btn btn-form-submit">SUBMIT</button>
              <text className="clear-contact-form-button" onClick={this.formClear.bind(this)}>CLEAR</text>
                <div className="social-media-icons">
                  <a target="_blank" href="http://linkedin.com/in/william-korslin-7a211312a/"><img src="linked-in-icon.png" className="social-icon" alt="linked-in"/></a>
                </div>
            </div>
</nav>

    )}};
export default Navbar;
