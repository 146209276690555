import React, { Component, createRef } from "react";
import HyperModal from 'react-hyper-modal';
// import instance from "../../api/axios";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./about.css";
// const sql = require('mssql');

class About extends Component {
  // Component's constructor.
  constructor(props) {
    // super(props) called to ensure that this.props is undefined in this constructor.
    super(props);
    this.fnameRef = createRef()
    this.lnameRef = createRef()
    this.emailRef = createRef()
    this.passRef = createRef()
    this.confRef = createRef()
    // Setting the state for this component with empty date, time, email, and password keys.
  this.state = {
    firstName: "",
    lastName: "",
    email: "",
    isModalOpen: false,
    modalBlurb: "",
    modalTitle: ""
    // password: "",
    // confirm: ""
  };
};

openFullTab (event)  {
  // document.getElementById("resume-card").style.height = "750px";
  // document.getElementById("resume-tab").style.display = "none";
  document.getElementById("bios-ul-2").style.display = "block";
  // document.getElementById("filler-container").style.height = "50px";

};

openModal(event) {
  this.setState({ modalBlurb: event.currentTarget.dataset.div_id });
  this.setState({ modalTitle: event.currentTarget.dataset.bind });

  this.setState({ isModalOpen: true });

};

closeModal() {
  this.setState({ isModalOpen: false });
};





  render() {
    const { isModalOpen } = this.state;
    return (
      <div>
      <img src="hancock.jpg" className="background-img" alt="background"/>
      <img src="hancock-mobile.jpg" className="background-img-mobile" alt="background"/><br/><br/><br/><br/>

      <br/><br/><br/><br/>

      <div className="container-about">
        {/* <h3 className="about-title">About Us</h3> */}
        {/* <p className="about-p"><text className="playfair-text-leading">KorslinHCE</text> is a healthcare strategic planning <text className="playfair-text-leading">consulting firm that works with its clients</text> to drive growth, <text className="playfair-text-leading">inspire innovation</text> and achieve transformational cultural change in a <text className="playfair-text-leading">rapidly changing environment.</text></p> */}

        {/* <div className="row-200">
        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 padding-0"> */}

              {/* <div className="col-xs-7 col-sm-7 col-md-7 col-lg-7 padding-0"> */}
                {/* <p className="about-p-two"><text className="playfair-text"><br/><br/>We do this</text> through creative and <text className="playfair-text">thoughtful collaboration with our clients</text>, assisting them in<text className="playfair-text"> evaluating their business</text> and, where necessary, modifying strategies to ensure they are positioned to take advantage of opportunities unfolding in the marketplace.  <br/><br/>We apply <text className="playfair-text">decades of healthcare industry experience </text>to address the many challenges facing our clients in today’s world.</p> */}
              {/* </div> */}
              {/* <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 padding-0"> */}

            {/* <div className="col-xs-5 col-sm-5 col-md-5 col-lg-5 padding-0"> */}
   

            <h3 className="mission-title">Mission</h3>
            <p className="mission-statement">Our Mission is to work with our clients to help modernize U.S. Healthcare through innovation and process re-engineering, streamlining access to vital products and services for our nation's most vulnerbale citizens, the frail and elderly.</p>
            <h3 className="values-title">Values</h3>
            {/* <br/><h9 className="click-below">[Click Below to Read More]</h9> */}
            <div className="row-999">
            {/* <div className="col-xs-5 col-sm-5 col-md-5 col-lg-5 col-xl-5 padding-0"> */}
              {/* <ul className="about-list"> */}
                  <text className="value-header">Growth:</text><p className="mission-style-p-bold">We believe continual growth is achieved by learning from our experiences and that expanding our knowledge helps conquer ever more challenging tasks and becoming better people - to inspire creativity, discipline, empathy and grit.</p>
                  {/* <p className="mission-style-p-about">We believe continual growth is achieved by learning from our experiences and that expanding our knowledge helps conquer ever more challenging tasks and becoming better people - to inspire creativity, discipline, empathy and grit.</p><br/> */}
                  <text className="value-header">Collaboration:</text><p className="mission-style-p-bold">Driven by a desire to succeed, we believe in working together as a team to create successful outcomes for all associates including clients & their employees, vendors, payers, investors, or patients.</p>
                  {/* <p className="mission-style-p-about">Driven by a desire to succeed, we believe in workign together as a team to create successful outcomes for all associates including clients & their employees, vendors, payers, investors, or patients.</p><br/> */}
                  <text className="value-header">Fun:</text><p className="mission-style-p-bold">We believe in an enjoyable work experience through physical, mental & spiritual wellness; fostering creativity, excitement & enthusiasm while enhancing quality of life.</p>
                  {/* <p className="mission-style-p-about">We believe an enjoyable work experience through physical, mental & spiritual wellness; fostering creativity, excitement & enthusiasm while enhancing quality of life.</p><br/> */}
                  <text className="value-header">Family:</text><p className="mission-style-p-bold">We believe in treating clients, physicians, patients, employees, customers and others we serve like family; with dignity, empathy, compassion, respect, and love.  We earn trust through our actions and hold oureslves accountable for living our values.</p>
                  {/* <p className="mission-style-p-about">We believe in treating clients, physicians, pateints, employees, customers and others we serve like family; with dignity, empathy, compassion, respect, and love.  We earn trust through our actions and hold oureslves accountable for living our values.</p><br/><br/> */}
              {/* </ul> */}
                  {/* </div> */}

      {/* <div className="col-xs-7 col-sm-7 col-md-7 col-lg-7 col-xl-7 padding-0">
        <div className="blurb-container">
         <h3 className="side-title">{this.state.modalTitle}</h3>
         <h5 className="blurb">{this.state.modalBlurb}</h5>
         </div>
      </div> */}
        </div>
        <div className="row-1111" id="11">
                  <text className="value-header">Growth:</text><br/><text className="value-text">We believe continual growth is achieved by learning from our experiences and that expanding our knowledge helps conquer ever more challenging tasks and becoming better people - to inspire creativity, discipline, empathy and grit.</text><br/><br/>
                  <text className="value-header">Collaboration:</text><br/><text className="value-text">Driven by a desire to succeed, we believe in working together as a team to create successful outcomes for all associates including clients & their employees, vendors, payers, investors, or patients.</text><br/><br/>
                  <text className="value-header">Fun:</text><br/><text className="value-text">We believe in an enjoyable work experience through physical, mental & spiritual wellness; fostering creativity, excitement & enthusiasm while enhancing quality of life.</text><br/><br/>
                  <text className="value-header">Family:</text><br/><text className="value-text">We believe in treating clients, physicians, patients, employees, customers and others we serve like family; with dignity, empathy, compassion, respect, and love.  We earn trust through our actions and hold oureslves accountable for living our values.</text>
        </div>
        </div>
        </div>
    );
  }
}


export default About;
