import React, { Component, createRef } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./services.css";

class Services extends Component {
  // Component's constructor.
  constructor(props) {
    // super(props) called to ensure that this.props is undefined in this constructor.
    super(props);
    this.resetEmailRef = createRef()

    // Setting the state for this component with empty date, time, email, and password keys.
  this.state = {
    firstName: "",
    lastName: "",
    email: ""
    // password: "",
    // confirm: ""
  };
}

// Function setting state of the email key.
  submitReset(event) {
    event.preventDefault();
      if (this.resetEmailRef.current.value == "") {
        alert("Please enter your email address.")
      } else {
      alert("We have sent a password re-set link to the following email address:  " + this.resetEmailRef.current.value);
      window.location.href = "/";
  }};

  submitBilling() {
    document.getElementById("one").style.display = "none";
    document.getElementById("two").style.display = "none";
    document.getElementById("three").style.display = "block";
    document.getElementById("two-description").style.display = "none";
    document.getElementById("three-description").style.display = "block";
    document.getElementById("form-registration").style.display = "none";
    document.getElementById("billing-form").style.display = "none";
    document.getElementById("confirmation-form").style.display = "block";
  };

  confirm() {
    document.getElementById("one").style.display = "none";
    document.getElementById("two").style.display = "none";
    document.getElementById("three").style.display = "none";
    document.getElementById("four").style.display = "block";
    document.getElementById("two-description").style.display = "none";
    document.getElementById("three-description").style.display = "none";
    document.getElementById("form-registration").style.display = "none";
    document.getElementById("billing-form").style.display = "none";
    document.getElementById("confirmation-form").style.display = "none";

  };

  // Function setting the state of the date key.
  onUpdate(dateChosen) {
    this.setState({
      date: dateChosen
    });
  };

  // Function setting the state of the time key.
  onTimeUpdate(time) {
    this.setState({
      time: time
    })
  };

  // Function ensuring that the component mounts with the updated state key value pairs.
  componentDidMount() {

  };

  returnToContact() {
    document.getElementById("form-registration").style.display = "block";
    document.getElementById("one").style.display = "block";
    document.getElementById("two").style.display = "none";
    document.getElementById("one-description").style.display = "block";
    document.getElementById("two-description").style.display = "none";
    document.getElementById("three-description").style.display = "none";
    document.getElementById("three").style.display = "none";
    document.getElementById("four").style.display = "none";
    document.getElementById("billing-form").style.display = "none";
    document.getElementById("confirmation-form").style.display = "none";
  }

  returnToBilling() {
    document.getElementById("billing-form").style.display = "block";
    document.getElementById("form-registration").style.display = "none";
    document.getElementById("one").style.display = "none";
    document.getElementById("two").style.display = "block";
    document.getElementById("one-description").style.display = "none";
    document.getElementById("two-description").style.display = "block";
    document.getElementById("three-description").style.display = "none";
    document.getElementById("three").style.display = "none";
    document.getElementById("four").style.display = "none";
    document.getElementById("confirmation-form").style.display = "none";
  }


  render() {
    return (
      <div>
       

        <div className="container-services">
        <h3 className="services-title">Services</h3>

            <div className="row-99">
            <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1 padding-0">
              </div>
            <div className="col-sm-5 col-md-5 col-lg-5 col-xl-5 padding-0">
            <div className="card-services-left"><br/>
            <img src="hancock.jpg" className="background-img" alt="background"/>
        <img src="hancock-mobile.jpg" className="background-img-mobile" alt="background"/>
           </div>
          </div>
          </div>
            <div className="col-sm-5 col-md-5 col-lg-5 col-xl-5 padding-0">
          <div className="card-services"><br/>
              <ul className="services-list-right">
              <li className="services-list-item">Strategic Business Assessment</li>
                <li className="services-list-item">Operational Value Stream Mapping & Performance Improvement</li>
                <li className="services-list-item">Business Model Evaluation & S.W.O.T. Analysis</li>
                <li className="services-list-item">Organization Structure & Team Assessment</li>
                <li className="services-list-item">Customer Experience Enhancement Programs</li>
                <li className="services-list-item">M&A Guidance</li>
                <li className="services-list-item">Ecosystem and Trend Analysis</li>
                <li className="services-list-item">Product Expansion, Sales & Marketing Strategy Development</li>
                <li className="services-list-item">Technology Assessment & IT Innovation Strategy</li>
                <li className="services-list-item">Competitition Assessment:  Competitive Advantages/Value Proposition</li>
                <li className="services-list-item">Financial Systems Analysis & Development Strategies</li>
              </ul>
           </div>
          </div>
          <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1 padding-0">
              </div>
          <div className="mobile-list">
          <ul className="services-list-left">
                <li className="services-list-item">Strategic Business Assessment</li>
                <li className="services-list-item">Operational Value Stream Mapping & Performance Improvement</li>
                <li className="services-list-item">Business Model Evaluation & S.W.O.T. Analysis</li>
                <li className="services-list-item">Organization Structure & Team Assessment</li>
                <li className="services-list-item">Customer Experience Enhancement Programs</li>
                <li className="services-list-item">M&A Guidance</li>
                <li className="services-list-item">Ecosystem and Trend Analysis</li>
                <li className="services-list-item">Product Expansion, Sales & Marketing Strategy Development</li>
                <li className="services-list-item">Technology Assessment & IT Innovation Strategy</li>
                <li className="services-list-item">Competitition Assessment:  Competitive Advantages/Value Proposition</li>
                <li className="services-list-item">Financial Systems Analysis & Development Strategies</li>
              </ul>
          </div>
          </div>
        </div>
      
    
    );
  }
}


export default Services;
