import React, { Component, createRef } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./expertise.css";
import HyperModal from 'react-hyper-modal';

class Expertise extends Component {
  // Component's constructor.
  constructor(props) {
    // super(props) called to ensure that this.props is undefined in this constructor.
    super(props);
    this.resetEmailRef = createRef()

    // Setting the state for this component with empty date, time, email, and password keys.
  this.state = {
    isModalOpen: false,
    firstName: "",
    lastName: "",
    email: "",
    blurb: "",
    title: ""
    // password: "",
    // confirm: ""
  };
}

openModal() {
  this.setState({isModalOpen: true});
};

modalBlurb (event) {
  this.setState({ blurb: event.currentTarget.dataset.div_id });
  this.setState({ title: event.currentTarget.dataset.bind });
  this.setState({isModalOpen: true});
};

closeModal() {
  this.setState({ isModalOpen: false });
};

populateBlurb(event) {
  // alert("event: " + event.currentTarget.dataset.div_id);
  this.setState({ blurb: event.currentTarget.dataset.div_id });
  this.setState({ title: event.currentTarget.dataset.bind });
};


// Function setting state of the email key.
  submitReset(event) {
    event.preventDefault();
      if (this.resetEmailRef.current.value == "") {
        alert("Please enter your email address.")
      } else {
      alert("We have sent a password re-set link to the following email address:  " + this.resetEmailRef.current.value);
      window.location.href = "/";
  }};

  submitBilling() {
    document.getElementById("one").style.display = "none";
    document.getElementById("two").style.display = "none";
    document.getElementById("three").style.display = "block";
    document.getElementById("two-description").style.display = "none";
    document.getElementById("three-description").style.display = "block";
    document.getElementById("form-registration").style.display = "none";
    document.getElementById("billing-form").style.display = "none";
    document.getElementById("confirmation-form").style.display = "block";
  };

  confirm() {
    document.getElementById("one").style.display = "none";
    document.getElementById("two").style.display = "none";
    document.getElementById("three").style.display = "none";
    document.getElementById("four").style.display = "block";
    document.getElementById("two-description").style.display = "none";
    document.getElementById("three-description").style.display = "none";
    document.getElementById("form-registration").style.display = "none";
    document.getElementById("billing-form").style.display = "none";
    document.getElementById("confirmation-form").style.display = "none";

  };

  // Function setting the state of the date key.
  onUpdate(dateChosen) {
    this.setState({
      date: dateChosen
    });
  };

  // Function setting the state of the time key.
  onTimeUpdate(time) {
    this.setState({
      time: time
    })
  };

  // Function ensuring that the component mounts with the updated state key value pairs.
  componentDidMount() {

  };

  returnToContact() {
    document.getElementById("form-registration").style.display = "block";
    document.getElementById("one").style.display = "block";
    document.getElementById("two").style.display = "none";
    document.getElementById("one-description").style.display = "block";
    document.getElementById("two-description").style.display = "none";
    document.getElementById("three-description").style.display = "none";
    document.getElementById("three").style.display = "none";
    document.getElementById("four").style.display = "none";
    document.getElementById("billing-form").style.display = "none";
    document.getElementById("confirmation-form").style.display = "none";
  }

  returnToBilling() {
    document.getElementById("billing-form").style.display = "block";
    document.getElementById("form-registration").style.display = "none";
    document.getElementById("one").style.display = "none";
    document.getElementById("two").style.display = "block";
    document.getElementById("one-description").style.display = "none";
    document.getElementById("two-description").style.display = "block";
    document.getElementById("three-description").style.display = "none";
    document.getElementById("three").style.display = "none";
    document.getElementById("four").style.display = "none";
    document.getElementById("confirmation-form").style.display = "none";
  }


  render() {
    const { isModalOpen } = this.state;

    return (
      <div>
        <img className="rodgers-expertise" src="riverwalk-min.jpg" alt="First slide"/>
        <img className="rodgers-expertise-mobile" src="riverwalk-min-2.jpg" alt="First slide"/>

        <div className="container-expertise">
        <div className="row-500">
        <h3 className="expertise-title">Expertise</h3><br/><h9 className="click-below">[Click Below to Read More]</h9><br/><br/>
        <div className="col-xs-5 col-sm-5 col-md-5 col-lg-5 col-xl-5 padding-0">
          <ul className="expertise-list">
            <li className="expertise-list-item" onClick={this.populateBlurb.bind(this)} data-bind={"Financial Systems | Performance Measurement KPIs | Financial Planning & Reporting"} data-div_id={"We believe a fundamental cornerstone of financial strength is a well-developed financial system which is fully integrated with the Company's operating system as a threshold requirement.  More important is the information it provides and how the team is aligned around and aware of P&L responsibilities.  KPIs, dashboards, performance improvement programs, goal and performance measuring metrics should influence decision-making regarding organization structure, business model modifications, process re-engineering requirements and responsibilities and future plans."} >Financial Systems | Performance Measurement KPIs | Financial Planning & Reporting</li>
            <li className="expertise-list-item" onClick={this.populateBlurb.bind(this)} data-bind={"Financial Strength Assessment"} data-div_id={"Our Assessment process begins with a financial review to understand the Company's financial position (overall and by business unit).  A detailed review of financial reports (profit & loss, cash flow and balance sheet trends) is completed to provide a full understanding of the clients position to guide transformation or performance improvement processes."}>Financial Strength Assessment</li>
            <li className="expertise-list-item" onClick={this.populateBlurb.bind(this)} data-bind={"Supply Chain | Procurement Opportunities"} data-div_id={"Product costs and the way those products are brought to your customers represent major factors in profit & loss performance and customer experience.  Our experts apply over four decades of experience to help our clients optimize supply chain and procurement efficiencies."}>Supply Chain | Procurement Opportunities</li>
            <li className="expertise-list-item" onClick={this.populateBlurb.bind(this)} data-bind={"Culture Development | Transformation Guidance"} data-div_id={"We work with our client leadership to understand the Company's human resource & culture development initiatives and how they lean into Mission, Vision, Values, Strengths and Drivers."}>Culture Development | Transformation Guidance</li>
            <li className="expertise-list-item" onClick={this.populateBlurb.bind(this)} data-bind={"Operations Assessment | Performance Improvement"} data-div_id={"We collaborate with our clients to assess present state operations, operating and financial systems, organization structure, business models and growth strategies to identify opportunities to achieve greater operational success.  We then help design new strategies and implementation plans to achieve sustainable transformational change."}>Operations Assessment | Performance Improvement</li>
            <li className="expertise-list-item" onClick={this.populateBlurb.bind(this)} data-bind={"Ecosystem | Industry Trend Analysis"} data-div_id={"We provide a client-specific ecosystem analysis along with industry trends analytics to help position the Company to take advantage of opportunities unfolding in the marketplace.  COVID-19 has accelerated both innovation and innovation adoption, which will have a profound effect on the future of healthcare delivery, which will create many opportunities."} >Ecosystem | Industry Trend Analysis</li>
            <li className="expertise-list-item" onClick={this.populateBlurb.bind(this)} data-bind={"Employee Engagement & Development | Organization Transformation"} data-div_id={"Any company's most important asset is its people.  We work with our clients to understand organization structure, process improvements that may require organizational redesign, human resource allocation, talent acquisition and training programs as well as training programs as well as vision building the right team & the right sized team"}>Employee Engagement & Development | Organization Transformation</li>
            <li className="expertise-list-item" onClick={this.populateBlurb.bind(this)} data-bind={"Customer Experience Enhancement | Growth Strategy Development"} data-div_id={"A key pillar of success for all companies is how customers perceive their relationship.  Managing customer expectations is challenging in today's evolving marketplace, particularly, in the DMEPOS segment where customer satisfaction is measured by the beneficiary, the physician, the facility where the beneficiary resides and the payer.  Our team works with you to create effective go-to-market sales and marketing strategies and helps develop technologies required to capture meaningful performance data and communicate across your ecosystem."}>Customer Experience Enhancement | Growth Strategy Development</li>
        </ul>
      </div>
      <div className="col-xs-7 col-sm-7 col-md-7 col-lg-7 col-xl-7 padding-0">
        <div className="blurb-container">
         <h3 className="side-title">{this.state.title}</h3>
         <h5 className="blurb">{this.state.blurb}</h5>
         </div>
      </div>
      <div className="stones-img-container">
            <img src="stones-min.jpg" className="zen-stone-img" alt="zen"/>
         </div>
      </div>
      <div className="row-600">
        <h3 className="expertise-title">Expertise</h3><br/><h9 className="click-below">[Click Below to Read More]</h9>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 padding-0">
          <ul className="expertise-list">
            <li className="expertise-list-item" onClick={this.modalBlurb.bind(this)}  data-bind={"Financial Systems | Performance Measurement KPIs | Financial Planning & Reporting"} data-div_id={"We believe a fundamental cornerstone of financial strength is a well-developed financial system which is fully integrated with the Company's operating system as a threshold requirement.  More important is the information it provides and how the team is aligned around and aware of P&L responsibilities.  KPIs, dashboards, performance improvement programs, goal and performance measuring metrics should influence decision-making regarding organization structure, business model modifications, process re-engineering requirements and responsibilities and future plans."} >Financial Systems | Performance Measurement KPIs | Financial Planning & Reporting</li>
            <li className="expertise-list-item" onClick={this.modalBlurb.bind(this)}  data-bind={"Financial Strength Assessment"} data-div_id={"Our Assessment process begins with a financial review to understand the Company's financial position (overall and by business unit).  A detailed review of financial reports (profit & loss, cash flow and balance sheet trends) is completed to provide a full understanding of the clients position to guide transformation or performance improvement processes."}>Financial Strength Assessment</li>
            <li className="expertise-list-item" onClick={this.modalBlurb.bind(this)}  data-bind={"Supply Chain | Procurement Opportunities"} data-div_id={"Product costs and the way those products are brought to your customers represent major factors in profit & loss performance and customer experience.  Our experts apply over four decades of experience to help our clients optimize supply chain and procurement efficiencies."}>Supply Chain | Procurement Opportunities</li>
            <li className="expertise-list-item" onClick={this.modalBlurb.bind(this)}  data-bind={"Culture Development | Transformation Guidance"} data-div_id={"We work with our client leadership to understand the Company's human resource & culture development initiatives and how they lean into Mission, Vision, Values, Strengths and Drivers."}>Culture Development | Transformation Guidance</li>
            <li className="expertise-list-item" onClick={this.modalBlurb.bind(this)}  data-bind={"Operations Assessment | Performance Improvement"} data-div_id={"We collaborate with our clients to assess present state operations, operating and financial systems, organization structure, business models and growth strategies to identify opportunities to achieve greater operational success.  We then help design new strategies and implementation plans to achieve sustainable transformational change."}>Operations Assessment | Performance Improvement</li>
            <li className="expertise-list-item" onClick={this.modalBlurb.bind(this)}  data-bind={"Ecosystem | Industry Trend Analysis"} data-div_id={"We provide a client-specific ecosystem analysis along with industry trends analytics to help position the Company to take advantage of opportunities unfolding in the marketplace.  COVID-19 has accelerated both innovation and innovation adoption, which will have a profound effect on the future of healthcare delivery, which will create many opportunities."} >Ecosystem | Industry Trend Analysis</li>
            <li className="expertise-list-item" onClick={this.modalBlurb.bind(this)}  data-bind={"Employee Engagement & Development | Organization Transformation"} data-div_id={"Any company's most important asset is its people.  We work with our clients to understand organization structure, process improvements that may require organizational redesign, human resource allocation, talent acquisition and training programs as well as training programs as well as vision building the right team & the right sized team"}>Employee Engagement & Development | Organization Transformation</li>
            <li className="expertise-list-item" onClick={this.modalBlurb.bind(this)}  data-bind={"Customer Experience Enhancement | Growth Strategy Development"} data-div_id={"A key pillar of success for all companies is how customers perceive their relationship.  Managing customer expectations is challenging in today's evolving marketplace, particularly, in the DMEPOS segment where customer satisfaction is measured by the beneficiary, the physician, the facility where the beneficiary resides and the payer.  Our team works with you to create effective go-to-market sales and marketing strategies and helps develop technologies required to capture meaningful performance data and communicate across your ecosystem."}>Customer Experience Enhancement | Growth Strategy Development</li>
        </ul>
      </div>
      <div className="stones-img-container-mobile">
            <img src="stones-min.jpg" className="zen-stone-img" alt="zen"/>
         </div>
      </div>
      </div>
      <HyperModal
              isOpen={isModalOpen}
              requestClose={this.closeModal.bind(this)}
              classes={"modal"}
            >
            <div className="modal-text"><br/><br/>
              <h5>{this.state.title}</h5><br/>
              <text className="blurb-text">{this.state.blurb}</text>
            </div>           
       </HyperModal>
      </div>
    );
  }
}


export default Expertise;
